import React from 'react';

const Anfitriones = props => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 63.03 63.03'
      xmlSpace='preserve'
    >
      <g>
        <path
          d='M19.03,61.07c-0.63-0.56-1.28-1.1-1.47-1.97c-0.78-1.67-0.57-3.33,0.03-4.99l-0.02,0.03
		c0.56-0.76-0.16-1.79,0.52-2.53l-0.02,0.03c0.53-2.5,0.68-5.09,1.52-7.53l-0.02,0.03c0.56-0.76-0.16-1.79,0.52-2.53l-0.02,0.03
		c0.4-2.37,0.66-4.77,1.5-7.04c0.08-0.11,0.09-0.22,0.02-0.34c0.23-1.14,0.64-2.31-0.56-3.2c-0.68-0.11-1.44,0.12-2.03-0.45
		c-1.99-0.51-4.03-0.84-5.97-1.54c-0.68-0.13-1.44,0.11-2.02-0.46c-1.8-0.58-3.77-0.54-5.43-1.58l0.05,0.03
		c-0.73-0.28-1.22-0.79-1.52-1.51l0.03,0.04c-1.24-1.65-1.19-3.3-0.03-4.96l-0.02,0.03c0.53-0.95,1.22-1.72,2.35-1.96
		c0.43-0.1,0.86-0.09,1.29,0.05c4.66,0.29,9.32,0.12,13.98,0.11c11.15-0.03,22.31,0.17,33.46-0.11c0.3-0.08,0.6-0.14,0.91-0.17
		c0.15,0.01,0.31,0.02,0.46,0.04c0.49,0.33,0.97,0.66,1.46,1c2.14,1.9,2.39,3.46,0.97,5.96l0.02-0.04c-0.32,0.7-0.8,1.22-1.53,1.5
		l0.05-0.03c-1.74,1-3.72,1.12-5.63,1.53c-0.62,0.19-1.23,0.38-1.85,0.56c-2.01,0.65-4.09,0.97-6.14,1.44
		c-0.76,0.87-2.53-0.12-2.86,1.54c1.22,2.93,1.18,6.12,1.96,9.14c-0.07,0.12-0.06,0.23,0.02,0.34c0.87,2.27,1,4.7,1.49,7.05
		l-0.03-0.04c0.69,0.78,0.01,1.83,0.52,2.65c-0.07,0.11-0.07,0.22,0.01,0.33c0.73,2,1.11,4.06,0.92,6.18
		c-0.07,0.85-0.17,1.81-1.13,2.28c-0.27,0.28-0.54,0.57-0.8,0.85c-0.41,0.83-1.31,0.82-2,1.16l0.04-0.02
		c-0.99,0.48-1.99,0.49-2.98-0.01l0.04,0.02c-1.79-0.54-2.93-1.74-3.53-3.49l0.01,0.03c-0.89-2.5-1.38-5.1-1.98-7.68
		c0.07-0.1,0.07-0.2-0.02-0.29c-0.5-1.56-1.01-3.12-1.29-4.74c-0.12-0.71-0.11-1.58-1.27-1.28c-0.09,0.11-0.1,0.22-0.03,0.35
		c-0.47,2.06-0.69,4.19-1.67,6.11c-0.48,0.57-0.36,1.24-0.32,1.9c-0.6,2.61-0.66,5.41-2.54,7.59l0.05-0.05
		c-0.69,0.75-1.52,1.29-2.51,1.55l0.04-0.02c-0.99,0.49-1.99,0.49-2.98,0l0.04,0.02C20.38,61.76,19.6,61.63,19.03,61.07z'
        />
        <path
          d='M28.01,13.09c-0.18-0.15-0.36-0.31-0.54-0.46c-0.58-0.39-1.06-0.89-1.44-1.48c-0.17-0.34-0.34-0.68-0.51-1.02
		C24.49,8.3,24.5,6.47,25.5,4.64c0.17-0.35,0.35-0.7,0.52-1.05c0.39-0.56,0.86-1.04,1.41-1.45c0.2-0.17,0.39-0.33,0.59-0.5
		c2.35-1.15,4.68-1.57,6.95,0.18c0.16,0.11,0.33,0.23,0.49,0.34C36.15,2.49,36.65,3,37,3.66l-0.01-0.02
		c1.72,2.5,1.77,4.99-0.02,7.47l0.02-0.03c-0.51,0.88-1.11,1.67-2.14,1.98c-0.27,0.17-0.54,0.34-0.8,0.51
		C31.95,14.49,29.96,14.07,28.01,13.09z'
        />
      </g>
    </svg>
  );
};

export default Anfitriones;
