import axios from './config';

export const createOrganization = ({ data }) => {
  return axios.post('/organizations', data).then(res => res.data.organization);
};

export const updateOrganization = ({ data, id }) => {
  return axios.patch(`/organizations/${id}`, data, {}).then(res => res.data.organization);
};

export const getOrganization = id => {
  if (!id) return {};
  return axios.get(`/organizations/${id}`).then(res => res.data.organization);
};

export const getOrganizationReservations = id => {
  return axios.get(`/organizations/${id}/reservations`).then(res => res.data.reservations);
};

export const getOrganizations = params => {
  return axios.get('/organizations', { params }).then(res => res.data);
};
