import React from 'react';

const Destinos = props => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 63.03 63.03'
      xmlSpace='preserve'
    >
      <g>
        <path
          d='M37.77,37.96c-0.34-0.42-0.69-0.85-1.03-1.27c-0.34-0.87-0.96-1.79-0.78-2.67c0.5-2.52-1.03-2.29-2.67-2.33
		c-4.37-0.1-8.75,0.38-13.1-0.25l0.03,0.01c-1.32-0.34-2.13-1.33-2.94-2.32c-0.1-0.13-0.24-0.24-0.27-0.39
		c-1.27-6.53-1.54-13.04,0.28-19.52c0.33-1.1,1.07-1.76,2.16-2.07c1.06-0.29,2.11-0.53,3.23-0.53c5.87,0.01,11.74,0.01,17.61,0
		c1.37,0,2.66,0.14,3.53,1.38c0.14,0.1,0.29,0.2,0.43,0.3c0.95,0.27,1.19,1.1,1.51,1.87l-0.01-0.01c1.04,1.17,0.27,2.55,0.45,3.82
		l0,0c1.12,0.58,2.3,0.16,3.45,0.18c1.53,0.03,2.94,0.28,4.17,1.25l-0.02-0.01c0.63,0.34,1.15,0.77,1.4,1.47l-0.03-0.04
		c0.69,0.84,0.79,1.84,0.79,2.88c-0.01,4.63-0.02,9.26,0,13.89c0,0.97-0.04,1.89-0.81,2.6c-0.3,0.42-0.6,0.85-0.9,1.27
		c-0.71,0.93-1.64,1.4-2.82,1.46c-3.85,0.19-7.7,0.31-11.55-0.11C39.1,38.73,38.35,38.55,37.77,37.96z'
        />
        <path
          d='M7.18,57.48C7.15,40.27,7.15,23.05,7.17,5.84c0-0.61-0.2-1.27,0.29-1.8L7.44,4.07
		C7.71,3.39,8.2,2.94,8.86,2.63l-0.04,0c0.8-0.3,1.61-0.63,2.33,0.19c0.28,0.25,0.56,0.5,0.84,0.76c0.83,0.74,0.61,1.74,0.61,2.66
		c0.01,16.83,0.01,33.66,0,50.5c0,0.92,0.21,1.91-0.6,2.66c-0.12,0.14-0.24,0.28-0.36,0.43c-1.25,1.45-2.49,0.93-3.74-0.01
		C7.89,59.81,7.21,59.3,7.18,57.48z'
        />
      </g>
    </svg>
  );
};

export default Destinos;
