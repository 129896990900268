import DefaultLayout from './components/common/Layouts/DefaultLayout';
import AppRouter from './Router';
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const defaultLayoutPages = ['/auth', '/recuperar-contrasena', '/cambiar-contrasena'];

function App() {
  const location = useLocation();

  const Wrapper = !defaultLayoutPages.includes(location.pathname) ? DefaultLayout : Fragment;
  return (
    <Wrapper>
      <AppRouter />
    </Wrapper>
  );
}

const Component = import.meta.env.PROD ? Sentry.withProfiler(App) : App;

export default Component;
