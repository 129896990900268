import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { Box, Flex, SimpleGrid, Text, Icon } from '@chakra-ui/react';
import {
  IconCashBanknote,
  IconPlaneDeparture,
  IconTicket,
  IconHeartHandshake,
  IconUsers,
  IconBottleFilled,
} from '@tabler/icons-react';

import { AppContext } from '../../../context/AppContext';

import { Inicio, Explora, Destinos, Anfitriones } from '../../../assets/icons/js';

const base = [
  {
    path: () => '/',
    label: 'navbar.default.home',
    SectionIcon: Inicio,
    filled: true,
    render: 'navbar',
  },
  {
    path: () => '/explora',
    label: 'navbar.default.explore',
    SectionIcon: Explora,
    filled: true,
    render: 'navbar',
  },
  {
    path: () => '/productos',
    label: 'navbar.default.products',
    SectionIcon: IconBottleFilled,
    filled: true,
    render: 'navbar',
  },
  {
    path: () => '/destinos',
    label: 'navbar.default.destinations',
    SectionIcon: Destinos,
    filled: true,
    render: 'navbar',
  },
  {
    path: () => '/anfitriones',
    label: 'navbar.default.hosts',
    SectionIcon: Anfitriones,
    filled: true,
    render: 'navbar',
  },
  {
    path: () => '/nosotros',
    label: 'navbar.default.aboutus',
    SectionIcon: IconHeartHandshake,
    filled: false,
    render: 'sidebar',
  },
];

const NavItems = {
  base,
  user: [
    {
      path: () => '/',
      label: 'navbar.default.home',
      SectionIcon: Inicio,
      filled: true,
      render: 'navbar',
    },
    {
      path: () => '/explora',
      label: 'navbar.default.explore',
      SectionIcon: Explora,
      filled: true,
      render: 'navbar',
    },
    {
      path: () => '/productos',
      label: 'navbar.default.products',
      SectionIcon: IconBottleFilled,
      filled: true,
      render: 'navbar',
    },
    {
      path: () => '/destinos',
      label: 'navbar.default.destinations',
      SectionIcon: Destinos,
      filled: true,
      render: 'navbar',
    },
    {
      path: () => '/anfitriones',
      label: 'navbar.default.hosts',
      SectionIcon: Anfitriones,
      filled: true,
      render: 'navbar',
    },
    {
      path: () => '/mis-viajes',
      label: 'navbar.user.travels',
      SectionIcon: IconPlaneDeparture,
      filled: false,
      render: 'sidebar',
    },
  ],
  organization: [
    {
      path: ({ _id }) => `/organizacion/dashboard/${_id}`,
      end: true,
      label: 'navbar.default.home',
      SectionIcon: Inicio,
      filled: true,
      render: 'navbar',
    },
    {
      path: ({ _id }) => `/organizacion/dashboard/${_id}/mis-reservaciones`,
      label: 'navbar.host.bookings',
      isNested: true,
      subroutes: [],
      SectionIcon: IconTicket,
      filled: false,
      render: 'navbar',
    },
    {
      path: ({ _id }) => `/organizacion/dashboard/${_id}/mis-pagos`,
      label: 'navbar.host.payments',
      SectionIcon: IconCashBanknote,
      filled: false,
      render: 'navbar',
    },
    {
      path: ({ _id }) => `/organizacion/dashboard/${_id}/mis-productos`,
      label: 'navbar.host.products',
      SectionIcon: IconBottleFilled,
      filled: false,
      render: 'navbar',
    },
    {
      path: ({ _id }) => `/organizacion/dashboard/${_id}/equipo`,
      label: 'navbar.host.team',
      SectionIcon: IconUsers,
      filled: false,
      render: 'navbar',
    },
  ],
};

const getNavItems = ({ user, organization }) => {
  if (organization && organization.completed) {
    return NavItems.organization;
  }
  if (user) {
    return NavItems.user;
  }
  return NavItems.base;
};

const NavbarItem = ({ href = '/', end, text, SectionIcon, filled }) => (
  <Box as={NavLink} to={href} end={end} flex='1' display='inline-flex' h='20'>
    {({ isActive }) => (
      <Flex
        bgColor={isActive ? 'brand.accent' : ''}
        w='full'
        justifyContent='space-around'
        lineHeight='6'
        alignItems='center'
        color={isActive ? 'white' : 'brand.secondary'}
        p='2'
        flexDirection='column'
      >
        <Icon
          w='6'
          h='6'
          as={SectionIcon}
          fill={filled ? (isActive ? 'white' : 'brand.secondary') : 'transparent'}
        />
        <Text fontWeight='bold' fontSize='2xs' textAlign='center' lineHeight='shorter'>
          {text}
        </Text>
      </Flex>
    )}
  </Box>
);

export default function MobileNavbar() {
  const { user, organization } = useContext(AppContext);
  const { t } = useTranslation(['layout']);

  return (
    <SimpleGrid
      columns='5'
      position='fixed'
      bgColor='white'
      boxShadow='rgba(0, 0, 0, 0.18) 0px 2px 11px 0px'
      bottom='0'
      w='full'
      zIndex='999'
      alignContent='center'
    >
      {getNavItems({ user, organization })
        .filter(item => item.render === 'navbar')
        .map(({ path, end, label, SectionIcon, filled }, index) => (
          <NavbarItem
            key={`mobileNavItem-${index}`}
            href={path(organization)}
            text={t(label)}
            end={end}
            SectionIcon={SectionIcon}
            filled={filled}
          />
        ))}
    </SimpleGrid>
  );
}
