import { Box } from '@chakra-ui/react';

export default function Loader({ cover = false }) {
  return (
    <Box width='full' position='relative' height={cover ? `100vh` : 96}>
      <div className='loader-container'>
        <div className='loader'>
          <div className='waves'></div>
        </div>
        <p className='loading-text'>Cargando...</p>
      </div>
    </Box>
  );
}
