import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  matchRoutes,
  createRoutesFromChildren,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './chakraTheme';
import ScrollToTop from './components/common/ScrollToTop';
import AppContextWrapper from './context/AppContext';
import './i18n';
import 'react-day-picker/dist/style.css';
import 'react-phone-number-input/style.css';
import '@splidejs/react-splide/css';
import './index.css';
import initializeGoogleAnalytics from './utils/googleA4';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://3b93b3ac444144158e99cac794c8aaa1@o4504816757899264.ingest.sentry.io/4504816800628736',
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: ['https://natoure-backend.fly.dev/api/'],
    tracesSampleRate: 0.1,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const helmetContext = {};

const cookiesButton = document.getElementById('accept-cookies');
const userConsent = localStorage.getItem('userConsent');

if (userConsent === 'accepted' || !import.meta.env.PROD) {
  document.querySelector('.cookie-banner').style.display = 'none';
  initializeGoogleAnalytics();
}

if (cookiesButton) {
  cookiesButton.addEventListener('click', () => {
    document.querySelector('.cookie-banner').style.display = 'none';
    localStorage.setItem('userConsent', 'accepted');
    initializeGoogleAnalytics();
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider context={helmetContext}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <AppContextWrapper>
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </AppContextWrapper>
        </ChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </BrowserRouter>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
