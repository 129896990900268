import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

export default function AvoidIfLoggedIn({ children }) {
  const { isLoggedIn, hasOrganization, organizationId } = useContext(AppContext);

  if (isLoggedIn) {
    if (hasOrganization) {
      return <Navigate to={`/organizacion/dashboard/${organizationId}`} />;
    }
    return <Navigate to={`/explora`} />;
  }

  return children;
}
