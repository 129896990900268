import React from 'react';

const Inicio = props => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 63.03 63.03'
      xmlSpace='preserve'
    >
      <path
        d='M39.5,55.68c-0.75-1.15-0.37-2.43-0.39-3.65c-0.05-4.41-0.07-8.82,0.01-13.23c0.03-1.59-0.43-2.24-2.12-2.19
   c-3.66,0.1-7.33,0.11-10.99,0c-1.72-0.05-2.1,0.66-2.08,2.21c0.07,4.83,0.04,9.65,0.02,14.48c-0.01,4.14-1.96,6.12-6.09,6.19
   c-2.16,0.03-4.33,0.09-6.49-0.03c-3.4-0.2-5.28-2.25-5.29-5.67c-0.02-9.9,0.02-19.81-0.02-29.71C6.06,21,7.25,18.5,9.57,16.68
   c5.57-4.37,11.54-8.17,17.57-11.86c2.97-1.83,6.05-1.72,8.98,0.11c5.85,3.66,11.67,7.38,17.16,11.58c2.53,1.94,3.69,4.58,3.68,7.82
   c-0.05,9.74,0,19.48-0.02,29.21c-0.01,3.85-1.96,5.84-5.82,5.94c-2.16,0.06-4.33,0.1-6.49-0.02C42.15,59.32,40.04,58.51,39.5,55.68
   c0.67-2.76,0.19-5.57,0.27-8.36c0.09-3.05,0.02-6.1,0.02-9.15c0,4.95,0.01,9.9-0.02,14.86C39.76,53.9,40.05,54.84,39.5,55.68z'
      />
    </svg>
  );
};

export default Inicio;
