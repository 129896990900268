import MX from '../../assets/flags/mx.png';
import BR from '../../assets/flags/br.png';
import FR from '../../assets/flags/fr.png';
import US from '../../assets/flags/us.png';

export const months = [
  { value: 'enero', label: { es: 'Enero', en: 'January', fr: 'janvier', pt: 'janeiro' } },
  { value: 'febrero', label: { es: 'Febrero', en: 'February', fr: 'février', pt: 'fevereiro' } },
  { value: 'marzo', label: { es: 'Marzo', en: 'March', fr: 'mars', pt: 'março' } },
  { value: 'abril', label: { es: 'Abril', en: 'April', fr: 'avril', pt: 'abril' } },
  { value: 'mayo', label: { es: 'Mayo', en: 'May', fr: 'mai', pt: 'maio' } },
  { value: 'junio', label: { es: 'Junio', en: 'June', fr: 'juin', pt: 'junho' } },
  { value: 'julio', label: { es: 'Julio', en: 'July', fr: 'juillet', pt: 'julho' } },
  { value: 'agosto', label: { es: 'Agosto', en: 'August', fr: 'août', pt: 'agosto' } },
  {
    value: 'septiembre',
    label: { es: 'Septiembre', en: 'September', fr: 'septembre', pt: 'setembro' },
  },
  { value: 'octubre', label: { es: 'Octubre', en: 'October', fr: 'octobre', pt: 'outubro' } },
  {
    value: 'noviembre',
    label: { es: 'Noviembre', en: 'November', fr: 'novembre', pt: 'novembro' },
  },
  {
    value: 'diciembre',
    label: { es: 'Diciembre', en: 'December', fr: 'décembre', pt: 'dezembro' },
  },
];

export const macrosTypes = [
  {
    value: 'ecoturismo',
    label: { es: 'Ecoturismo', en: 'Ecotourism', fr: 'Écotourisme', pt: 'Ecoturismo' },
  },
  {
    value: 'comunitario',
    label: { es: 'Comunitario', en: 'Community', fr: 'Communauté', pt: 'Comunitário' },
  },
  { value: 'aventura', label: { es: 'Aventura', en: 'Adventure', fr: 'Aventure', pt: 'Aventura' } },
  {
    value: 'biocultural',
    label: { es: 'Biocultural', en: 'Biocultural', fr: 'Bioculturel', pt: 'Biocultural' },
  },
  {
    value: 'regenerativo',
    label: { es: 'Regenerativo', en: 'Regenerative', fr: 'Régénérateur', pt: 'Regenerativo' },
  },
  {
    value: 'agroturismo',
    label: { es: 'Agroturismo', en: 'Agritourism', fr: 'Agritourisme', pt: 'Agriturismo' },
  },
  {
    value: 'rutas gastronómicas',
    label: {
      es: 'Rutas gastronómicas',
      en: 'Gastronomic routes',
      fr: 'Routes gastronomiques',
      pt: 'Rotas gastronômicas',
    },
  },
  {
    value: 'ciencia y conservación',
    label: {
      es: 'Ciencia y conservación',
      en: 'Science and conservation',
      fr: 'Science et conservation',
      pt: 'Ciência e conservação',
    },
  },
];

export const macros = macrosTypes.map(macro => macro.value);

export const secondaryActivities = [
  { value: 'forestal', label: { es: 'Forestal', en: 'Forestal' } },
  { value: 'pesca', label: { es: 'Pesca', en: 'Fishing' } },
  { value: 'agropecuario', label: { es: 'Agropecuario', en: 'Agricultural' } },
  { value: 'manufactura', label: { es: 'Manufactura', en: 'Manufacturing' } },
  {
    value: 'procesos productivos',
    label: { es: 'Procesos productivos', en: 'Production processes' },
  },
];

export const inclusivePolicies = [
  {
    value: 'mayor participación activa y liderazgo de mujeres',
    label: {
      es: 'Mayor participación activa y liderazgo de mujeres',
      en: 'Increased active participation and leadership of women',
    },
  },
  {
    value: 'integración de pueblos indígenas y comunidades locales',
    label: {
      es: 'Integración de pueblos indígenas y comunidades locales',
      en: 'Integration of indigenous peoples and local communities',
    },
  },
  {
    value: 'personas con discapacidad',
    label: { es: 'Personas con discapacidad', en: 'People with disabilities' },
  },
  { value: 'adultos mayores', label: { es: 'Adultos mayores', en: 'Older adults' } },
  { value: 'LGBTT+', label: { es: 'LGBTT+', en: 'LGBTT+' } },
];

export const currencies = [
  {
    value: 'MXN',
    label: { es: 'MXN', en: 'MXN', fr: 'MXN', pt: 'MXN' },
    name: {
      es: 'pesos mexicanos',
      en: 'mexican pesos',
      fr: 'pesos mexicains',
      pt: 'pesos mexicanos',
    },
  },
  {
    value: 'EUR',
    label: { es: 'EUR', en: 'EUR', fr: 'EUR', pt: 'EUR' },
    name: { es: 'euros', en: 'euros', fr: 'euros', pt: 'euros' },
  },
  {
    value: 'USD',
    label: { es: 'USD', en: 'USD', fr: 'USD', pt: 'USD' },
    name: { es: 'dólares', en: 'dollars', fr: 'dollars', pt: 'dólares' },
  },
];

export const transportationTypes = [
  {
    value: 'transporte de ida',
    label: {
      es: 'Transporte de ida',
      en: 'One way transportation',
      fr: 'Transport aller simple',
      pt: 'Transporte de ida',
    },
  },
  {
    value: 'transporte de regreso',
    label: {
      es: 'Transporte de regreso',
      en: 'Return transportation',
      fr: 'Transport de retour',
      pt: 'Transporte de retorno',
    },
  },
  {
    value: 'transporte redondo',
    label: {
      es: 'Transporte redondo',
      en: 'Round trip transportation',
      fr: 'Transport aller-retour',
      pt: 'Transporte de ida e volta',
    },
  },
];

export const locales = {
  es: {
    short: 'es',
    long: 'es-MX',
    name: 'Español',
    flag: MX || 'https://countryflagsapi.com/png/mx',
  },
  en: {
    short: 'en',
    long: 'en-US',
    name: 'English',
    flag: US || 'https://countryflagsapi.com/png/us',
  },
  fr: {
    short: 'fr',
    long: 'fr-FR',
    name: 'Français',
    flag: FR || 'https://countryflagsapi.com/png/fr',
  },
  pt: {
    short: 'pt',
    long: 'pt-BR',
    name: 'Português',
    flag: BR || 'https://countryflagsapi.com/png/br',
  },
};

export const shipping_status = [
  { value: 'canceled', label: { es: 'Cancelado', fr: 'Annulé', en: 'Canceled', pt: 'Cancelado' } },
  {
    value: 'in preparation',
    label: {
      es: 'En preparación',
      en: 'In preparation',
      fr: 'En préparation',
      pt: 'Em preparação',
    },
  },
  { value: 'send', label: { es: 'Envíado', en: 'Send', fr: 'Envoyé', pt: 'Enviado' } },
];
export const accommodation_types = [
  { value: 'habitación', label: { es: 'Habitación', fr: 'Chambre', en: 'Room', pt: 'Quarto' } },
  {
    value: 'habitación compartida',
    label: {
      es: 'Habitación compartida',
      fr: 'Chambre partagée',
      en: 'Shared Room',
      pt: 'Quarto compartilhado',
    },
  },
  {
    value: 'cabaña',
    label: {
      es: 'Cabaña',
      en: 'Cabin',
      fr: 'Cabane',
      pt: 'Cabana',
    },
  },
  {
    value: 'glamping',
    label: {
      es: 'Glamping',
      en: 'Glamping',
      fr: 'Glamping',
      pt: 'Glamping',
    },
  },
  {
    value: 'ecocamping',
    label: {
      es: 'Ecocamping',
      en: 'Ecocamping',
      fr: 'Ecocamping',
      pt: 'Ecocamping',
    },
  },
  {
    value: 'espacio para camping',
    label: {
      es: 'Espacio para camping',
      en: 'Camping Space',
      fr: 'Espace de camping',
      pt: 'Espaço para camping',
    },
  },
];

export const accommodation_categories = [
  { value: 'standard', label: { es: 'Standard', fr: 'Standard', en: 'Standard', pt: 'Standard' } },
  {
    value: 'luxury',
    label: {
      es: 'Luxury',
      en: 'Luxury',
      fr: 'Luxury',
      pt: 'Luxury',
    },
  },
  {
    value: 'ecoluxury',
    label: {
      es: 'Ecoluxury',
      en: 'Ecoluxury',
      fr: 'Ecoluxury',
      pt: 'Ecoluxury',
    },
  },
];

export const bed_types = [
  {
    value: 'single',
    label: { es: 'cama individual', fr: 'lit simple', en: 'single bed', pt: 'cama de solteiro' },
  },
  {
    value: 'double',
    label: { es: 'cama doble', fr: 'lit double', en: 'double bed', pt: 'cama de casal' },
  },
  {
    value: 'queen',
    label: { es: 'cama queen', fr: 'lit queen', en: 'queen bed', pt: 'cama queen size' },
  },
  {
    value: 'king',
    label: { es: 'cama king', fr: 'lit king', en: 'king bed', pt: 'cama king size' },
  },
  { value: 'bunk', label: { es: 'litera', fr: 'lit superposé', en: 'bunk bed', pt: 'beliche' } },
  {
    value: 'sofa',
    label: { es: 'sofá cama', fr: 'canapé-lit', en: 'sofa bed', pt: 'sofá-cama' },
  },
  { value: 'futon', label: { es: 'futón', fr: 'futon', en: 'futon', pt: 'futon' } },
  { value: 'hammock', label: { es: 'hamaca', fr: 'hamac', en: 'hammock', pt: 'rede' } },
];

export const bathroom_types = [
  {
    value: 'shared',
    label: {
      es: 'baño compartido',
      fr: 'salle de bain partagée',
      en: 'shared bathroom',
      pt: 'casa de banho partilhada',
    },
  },
  {
    value: 'private',
    label: {
      es: 'baño privado',
      fr: 'salle de bain privée',
      en: 'private bathroom',
      pt: 'casa de banho privada',
    },
  },
];

export const service_options = [
  {
    value: 'wifi',
    label: { en: 'Wifi', es: 'Wifi', fr: 'Wifi', pt: 'Wifi' },
  },
  {
    value: 'parking',
    label: { en: 'Parking', es: 'Estacionamiento', fr: 'Stationnement', pt: 'Estacionamento' },
  },
  {
    value: 'pet_friendly',
    label: {
      en: 'Pet Friendly',
      es: 'Amigable para mascotas',
      fr: 'Animaux acceptés',
      pt: 'Pet Friendly',
    },
  },
  {
    value: 'laundry',
    label: { en: 'Laundry', es: 'Lavandería', fr: 'Laverie', pt: 'Lavanderia' },
  },
  {
    value: 'minibar',
    label: { en: 'Minibar', es: 'Minibar', fr: 'Minibar', pt: 'Minibar' },
  },
  {
    value: 'all_inclusive',
    label: { en: 'All Inclusive', es: 'Todo incluido', fr: 'Tout inclus', pt: 'Tudo Incluído' },
  },
  {
    value: 'wheelchair_access',
    label: {
      en: 'Wheelchair Access',
      es: 'Acceso para silla de ruedas',
      fr: 'Accès aux fauteuils roulants',
      pt: 'Acesso para cadeira de rodas',
    },
  },
  {
    value: 'bathtub',
    label: { en: 'Bathtub', es: 'Tina', fr: 'Baignoire', pt: 'Banheira' },
  },
  {
    value: 'air_conditioning',
    label: {
      en: 'Air Conditioning',
      es: 'Aire acondicionado',
      fr: 'Climatisation',
      pt: 'Ar condicionado',
    },
  },
  {
    value: 'television',
    label: { en: 'Television', es: 'Televisión', fr: 'Télévision', pt: 'Televisão' },
  },
  {
    value: 'room_service',
    label: {
      en: 'Room Service',
      es: 'Servicio a la habitación',
      fr: 'Service en chambre',
      pt: 'Serviço de quarto',
    },
  },
  {
    value: 'cleaning',
    label: { en: 'Cleaning', es: 'Limpieza', fr: 'Nettoyage', pt: 'Limpeza' },
  },
  {
    value: 'desk',
    label: { en: 'Desk', es: 'Escritorio', fr: 'Bureau', pt: 'Mesa de trabalho' },
  },
  {
    value: 'phone',
    label: { en: 'Phone', es: 'Teléfono', fr: 'Téléphone', pt: 'Telefone' },
  },
  {
    value: 'safe',
    label: { en: 'Safe', es: 'Caja de seguridad', fr: 'Coffre-fort', pt: 'Cofre' },
  },
  {
    value: 'wardrobe',
    label: { en: 'Wardrobe', es: 'Armario', fr: 'Armoire', pt: 'Guarda-roupa' },
  },
  {
    value: 'balcony',
    label: { en: 'Balcony', es: 'Balcón', fr: 'Balcon', pt: 'Varanda' },
  },
  {
    value: 'pool',
    label: { en: 'Pool', es: 'Alberca', fr: 'Piscine', pt: 'Piscina' },
  },
  {
    value: 'sea_view',
    label: { en: 'Sea View', es: 'Vista al mar', fr: 'Vue sur la mer', pt: 'Vista para o mar' },
  },
  {
    value: 'garden_view',
    label: {
      en: 'Garden View',
      es: 'Vista al jardín',
      fr: 'Vue sur le jardin',
      pt: 'Vista para o jardim',
    },
  },
  {
    value: 'kitchen',
    label: { en: 'Kitchen', es: 'Cocina', fr: 'Cuisine', pt: 'Cozinha' },
  },
];

export const attraction_types = [
  {
    value: 'atractivo natural',
    label: {
      es: 'Atractivo natural',
      fr: 'Attraction naturelle',
      en: 'Natural attraction',
      pt: 'Atração natural',
    },
  },
  {
    value: 'atractivo cultural',
    label: {
      es: 'Atractivo cultural',
      fr: 'Attraction culturelle',
      en: 'Cultural attraction',
      pt: 'Atração cultural',
    },
  },
];
