import React from 'react';

const Explora = props => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 63.03 63.03'
      xmlSpace='preserve'
    >
      <path
        d='M11.08,25.13c0.31-0.19,0.62-0.39,0.93-0.58c0.96-0.61,1.99-1.08,3.07-1.42c0.31-0.19,0.62-0.39,0.93-0.58
	c0.96-0.61,1.99-1.08,3.07-1.42c0.31-0.19,0.62-0.39,0.93-0.58c0.96-0.61,1.99-1.09,3.08-1.41c0.3-0.19,0.61-0.38,0.91-0.57
	c0.96-0.63,1.99-1.09,3.09-1.42c0.31-0.2,0.61-0.4,0.92-0.6c0.96-0.6,1.98-1.05,3.05-1.41c0.32-0.19,0.63-0.39,0.95-0.58
	c0.96-0.6,1.99-1.07,3.07-1.41c0.31-0.2,0.62-0.39,0.93-0.59c0.96-0.6,1.99-1.08,3.07-1.41c0.31-0.2,0.62-0.39,0.93-0.59
	c0.97-0.6,1.99-1.09,3.09-1.4c0.3-0.2,0.6-0.39,0.9-0.59c0.94-0.62,1.97-1.04,3.02-1.43l-0.02,0.01c1.21-0.91,2.62-1.44,4-2.02
	l-0.01,0.01c1.92-1.23,3.87-1.98,5.95-0.31c0.16,0.1,0.33,0.21,0.49,0.31c0.67,0.35,1.2,0.83,1.52,1.53l-0.02-0.03
	c1.17,1.83,1.3,3.65-0.08,5.45c-0.41,0.21-0.43,0.58-0.39,0.97c-0.39,1.07-0.78,2.14-1.48,3.06l0,0c-0.58,1.38-1.15,2.76-1.99,4
	l0,0.01c-0.61,1.36-1.14,2.76-2.01,3.99l0-0.01c-0.58,1.38-1.13,2.77-2.01,4l0-0.01c-0.58,1.38-1.13,2.77-2,4.01l0-0.01
	c-0.58,1.38-1.13,2.77-2,4.01l0-0.01c-0.57,1.38-1.14,2.77-1.99,4.01l0,0c-0.59,1.37-1.14,2.76-2,4l0,0
	c-0.61,1.36-1.12,2.78-2.03,3.99l0.01-0.02c-0.56,1.39-1.12,2.79-2,4.02l0.01-0.01c-0.57,1.39-1.12,2.78-2.01,4.01l0.01-0.01
	c-0.51,1.12-0.71,2.45-2.06,2.99c-0.16,0.12-0.31,0.24-0.47,0.36c-1.95,1.74-3.93,1.52-5.94,0.12l0.03,0.02
	c-1.7-0.96-2.34-2.41-2.31-4.35c0.07-4.91,0.02-9.82,0.02-14.73c0-2.63-0.01-2.63-2.74-2.63c-4.58,0-9.16,0-13.73,0
	c-1.79,0-3.44-0.4-4.76-1.71c-0.61-0.57-1.29-1.1-1.44-2.01c-0.5-1.57-0.61-3.11,0.48-4.51l-0.02,0.02
	c0.88-1.59,2.31-2.48,3.99-3.02l-0.01,0.01C8.93,25.89,10,25.5,11.08,25.13z'
      />
    </svg>
  );
};

export default Explora;
