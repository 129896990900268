import { Hide } from '@chakra-ui/react';
import { Fragment, lazy, Suspense } from 'react';
import Loader from '../Loaders/Loader';
import Footer from './Footer';
import MobileNavbar from './MobileNavbar';
const Navbar = lazy(() =>
  import('./Navbar').catch(e => {
    if (e.message.includes('Failed to fetch dynamically imported module')) {
      window.location.reload();
    }
    return {};
  }),
);

export default function DefaultLayout({ children }) {
  return (
    <Fragment>
      <Suspense fallback={<Loader cover />}>
        <Navbar />
        {children}
        <Hide above='md'>
          <MobileNavbar />
        </Hide>
        <Footer />
      </Suspense>
    </Fragment>
  );
}
