import ReactGA from 'react-ga4';

const initializeGoogleAnalytics = () => {
  // Replace with your Measurement ID
  // It ideally comes from an environment variable
  ReactGA.initialize('G-QJ7R2RRX1J', {
    testmode: !import.meta.env.PROD,
  });
};

const trackGAEvent = (category, action, label) => {
  console.log('GA event:', category, ':', action, ':', label);
  // Send GA4 Event
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export default initializeGoogleAnalytics;
export { initializeGoogleAnalytics, trackGAEvent };
