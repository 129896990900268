import { Container, Flex, Box, Text, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IconBrandInstagram, IconBrandFacebook, IconBrandX } from '@tabler/icons-react';
import { NatoureIcon } from '../../../assets/icons/js';

export default function Footer() {
  const { t } = useTranslation(['layout']);
  return (
    <Container maxW={{ base: 'xl', lg: '5xl', xl: '7xl' }} w='full' centerContent>
      <Flex
        w='full'
        borderTop='1px solid'
        borderTopColor='brand.secondary'
        pt='8'
        pb={{ base: '24', md: '8' }}
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'center', lg: 'space-between' }}
        fontSize={{ base: 'sm', lg: 'md' }}
      >
        <Box>
          <Text fontWeight='bold' mb='4' textAlign={{ base: 'center', lg: 'left' }}>
            {t('footer.social')}
          </Text>
          <Flex gap='4' justifyContent='center'>
            <Flex
              gap='1'
              alignItems='center'
              as='a'
              href='https://www.facebook.com/Natoure.org/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Icon as={IconBrandFacebook} />
              <Text>Facebook</Text>
            </Flex>
            <Flex
              gap='1'
              alignItems='center'
              as='a'
              href='https://www.instagram.com/natoure.life'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Icon as={IconBrandInstagram} />
              <Text>Instagram</Text>
            </Flex>
            <Flex
              gap='1'
              alignItems='center'
              as='a'
              href='https://twitter.com/natoureorg'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Icon as={IconBrandX} />
              <Text>Twitter / X</Text>
            </Flex>
          </Flex>
        </Box>
        <Flex justifyContent='center' alignItems='center' flexDirection='column'>
          <Flex alignItems='center' flexDir={{ base: 'column', md: 'row' }} gap='4' mt='8'>
            <Link to='/terminos-y-condiciones'>
              <Text textDecoration='underline'>{t('footer.terms')}</Text>
            </Link>
            <Link to='/politica-de-privacidad'>
              <Text textDecoration='underline'>{t('footer.policy')}</Text>
            </Link>
          </Flex>
          <Text my='4' textAlign='center'>
            Copyright ©{new Date().getFullYear()}. Natoure. {t('footer.copyright')}
          </Text>
          <Box h='8' w='8' mb={{ base: 4, lg: 0 }}>
            <NatoureIcon color='#051755' />
          </Box>
        </Flex>
        <Flex
          flexDirection='column'
          justifyItems='center'
          alignItems={{ base: 'center', lg: 'end' }}
        >
          <Text fontWeight='bold' mb='2' textAlign={{ base: 'center', lg: 'right' }}>
            {t('footer.contactus')}
          </Text>
          <Text as='a' href='mailto:conectate@natoure.org'>
            conectate@natoure.org
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
}
