import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

export default function ProtectedRoute({ children, shouldBeOrganization = false }) {
  const { isLoggedIn, hasOrganization, organizationId } = useContext(AppContext);

  const shouldRender = shouldBeOrganization ? isLoggedIn && organizationId : isLoggedIn;

  if (!shouldRender) {
    if (isLoggedIn && hasOrganization) {
      return <Navigate to={`/organizacion/dashboard/${organizationId}`} />;
    }
    if (isLoggedIn && !hasOrganization) {
      return <Navigate to={`/explora`} />;
    }
    return <Navigate to={`/auth`} />;
  }

  return children;
}
