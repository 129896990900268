import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { loadStripe } from '@stripe/stripe-js';

import { getOrganization } from '../services/organization';
import { locales } from '../utils/constants/common';
import i18n from '../i18n';
import { logoutRequest } from '../services/auth';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);
const stripeBaseOptions = {
  appearance: {
    theme: 'stripe',
    variables: {
      colorPrimary: '#18955e',
      colorText: '#051555',
    },
  },
};

export const AppContext = createContext({});

const AppContextWrapper = ({ children }) => {
  const toast = useToast();
  const [user, setUserInfo] = useState(null);
  const [locale, setLocale] = useState(locales.es);
  const [organizationId, setOrganizationId] = useState(null);
  const { data } = useQuery(
    ['organization', organizationId],
    () => getOrganization(organizationId),
    { refetchOnWindowFocus: false, enabled: !!organizationId },
  );

  const isLoggedIn = !!user;
  const navigate = useNavigate();

  const updateUserInfo = userInfo => {
    if (userInfo.organization?._id) {
      const { _id } = userInfo.organization;
      setOrganizationId(_id);
      localStorage.setItem('organization', _id);
    }
    localStorage.setItem('user', JSON.stringify(userInfo.user));
    if (userInfo.token) localStorage.setItem('token', userInfo.token);
    setUserInfo(userInfo.user);
  };

  const updateOrganizationId = organizationId => {
    localStorage.setItem('organization', organizationId);
    setOrganizationId(organizationId);
  };

  const handleSetLocale = newLocale => {
    setLocale(newLocale);
    localStorage.setItem('locale', JSON.stringify(newLocale));
    i18n.changeLanguage(newLocale.long);
  };

  const logout = async path => {
    try {
      await logoutRequest();
      localStorage.removeItem('organization');
      localStorage.removeItem('user');
      setUserInfo(null);
      setOrganizationId(null);
      navigate('/auth', { state: { prevPath: path } });
    } catch (error) {
      console.error(error);
    }
  };

  const checkIfTokenExpires = (error, path) => {
    if (!error) return;
    if (error.name === 'JsonWebTokenError') {
      logout(path);
      toast({
        title: 'Tu sesión ha expirado',
        description: 'Debes iniciar sesión de nuevo para continuar',
        status: 'info',
        position: 'top-right',
        duration: 7000,
        isClosable: true,
      });
      return;
    }
    return;
  };

  useEffect(() => {
    const user = localStorage.getItem('user');
    const org = localStorage.getItem('organization');
    const locale = localStorage.getItem('locale');

    if (locale) {
      setLocale(JSON.parse(locale));
    }
    if (user) {
      setUserInfo(JSON.parse(user));
    }
    if (org) {
      setOrganizationId(org);
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        user,
        logout,
        updateUserInfo,
        isLoggedIn,
        hasOrganization: !!organizationId,
        organizationId,
        organization: data,
        updateOrganizationId,
        checkIfTokenExpires,
        locale,
        setLocale: handleSetLocale,
        stripeBaseOptions,
        stripePromise,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextWrapper;
